// extracted by mini-css-extract-plugin
export var button = "pricing-module--button--541bf";
export var button2 = "pricing-module--button2--e2ecc";
export var cardText = "pricing-module--cardText--3784f";
export var cardTitle = "pricing-module--cardTitle--97316";
export var couponContainer = "pricing-module--couponContainer--1c7e4";
export var divider = "pricing-module--divider--1e8ca";
export var each = "pricing-module--each--09654";
export var features = "pricing-module--features--aa270";
export var firstPriceCards = "pricing-module--firstPriceCards--362f2";
export var input = "pricing-module--input--b4964";
export var ourPricing = "pricing-module--ourPricing--74cdf";
export var paymentCol = "pricing-module--paymentCol--4c9b2";
export var priceCards = "pricing-module--priceCards--447fb";
export var pricingContainer = "pricing-module--pricingContainer--5a05b";
export var prodCardBody = "pricing-module--prodCardBody--f8f46";
export var prodDigit = "pricing-module--prodDigit--7db87";
export var rows = "pricing-module--rows--803eb";
export var save = "pricing-module--save--4ac0a";
export var tax = "pricing-module--tax--e0f09";
export var text1 = "pricing-module--text1--11a93";