import React from "react";
import PropTypes from "prop-types"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";

import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { PrimaryButton } from "@bit/numudev.numu_lib.primary-button";

import classnames from "classnames";
import * as styles from "../../style/components/pricing.module.scss";
import Text from "../../lib/NewText/Text";

const COMPANY_URL = process.env.GATSBY_COMPANY_URL;

const Pricing = ({ intl }) => (
  <>
    <Container className={styles.pricingContainer}>
      <Row className={styles.rows}>
        <Col sm lg md>
          <Card className={styles.firstPriceCards}>
            <Card.Body style={{ padding: 0 }}>
              <Card.Title>
                <Text title className={styles.ourPricing}>
                  <FormattedMessage id="pricingPage.heading1" />
                </Text>{" "}
              </Card.Title>
              <Card.Text>
                <Text text className={styles.text1}>
                  <FormattedMessage id="pricingPage.text1" />
                </Text>
              </Card.Text>
              <Row>
                <Col style={{ padding: 0 }}>
                  <Card.Text>
                    <Text text className={styles.features}>
                      <FormattedMessage id="pricingPage.feature1" />
                    </Text>
                  </Card.Text>
                </Col>
                <Col style={{ padding: 0 }}>
                  <Card.Text>
                    <Text text className={styles.features}>
                      <FormattedMessage id="pricingPage.feature2" />
                    </Text>
                  </Card.Text>
                </Col>
                <Col style={{ padding: 0 }}>
                  <Card.Text>
                    <Text text className={styles.features}>
                      <FormattedMessage id="pricingPage.feature3" />
                    </Text>
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row style={{ paddingTop: 0 }} className={styles.rows}>
        <Col sm lg md>
          <Card className={styles.priceCards}>
            <Card.Body>
              <Card.Title>
                <Text title className={styles.cardTitle}>
                  <FormattedMessage id="pricingPage.heading2" />
                </Text>
              </Card.Title>
              <Card.Text>
                <Text
                  text
                  className={styles.cardText}
                  style={{ marginBottom: "7px" }}
                >
                  <FormattedMessage id="pricingPage.text2" />
                  <br />
                  {intl.locale === "es" && (
                    <FormattedMessage id="pricingPage.text21" />
                  )}
                </Text>
              </Card.Text>
              <Row
                className={classnames({
                  mt: intl.locale === "es",
                  pdt: intl.locale === "en",
                })}
                style={{
                  position: "relative",
                  right: "25px",
                  alignItems: "center",
                }}
              >
                <Col className={styles.prodDigit} style={{ fontSize: "28px" }}>
                  5{" "}
                  <Text
                    text
                    style={{
                      position: "relative",
                      top: "-11px",
                      lineHeight: 2,
                    }}
                    className={styles.cardText}
                  >
                    <FormattedMessage id="pricingPage.creators" />
                  </Text>
                </Col>
                <Col
                  className={styles.prodDigit}
                  style={{ textAlign: "right", color: "#727176" }}
                >
                  <Text text className={styles.tax}>
                    <i>
                      <FormattedMessage id="pricingPage.tax" />
                    </i>
                  </Text>
                  20€/
                  <span className={styles.each}>
                    <FormattedMessage id="pricingPage.each" />
                  </span>
                </Col>
              </Row>
              <div className={styles.divider} />

              <Row
                style={{
                  position: "relative",
                  right: "25px",
                  alignItems: "center",
                }}
              >
                <Col className={styles.prodDigit} style={{ fontSize: "28px" }}>
                  20{" "}
                  <Text
                    text
                    className={styles.cardText}
                    style={{
                      position: "relative",
                      top: "-11px",
                      lineHeight: 2,
                    }}
                  >
                    <FormattedMessage id="pricingPage.creators" />
                  </Text>
                </Col>
                <Col
                  className={styles.prodDigit}
                  style={{ textAlign: "right", color: "#727176" }}
                >
                  15€/
                  <span className={styles.each}>
                    <FormattedMessage id="pricingPage.each" />
                  </span>
                  <Text text className={styles.save}>
                    <i>
                      <FormattedMessage id="pricingPage.save" /> 100€
                    </i>
                  </Text>
                </Col>
              </Row>
              <div className={styles.divider} />

              <Row
                style={{
                  position: "relative",
                  right: "25px",
                  alignItems: "center",
                }}
              >
                <Col className={styles.prodDigit} style={{ fontSize: "28px" }}>
                  50{" "}
                  <Text
                    text
                    className={styles.cardText}
                    style={{
                      position: "relative",
                      top: "-11px",
                      lineHeight: 2,
                    }}
                  >
                    <FormattedMessage id="pricingPage.creators" />
                  </Text>
                </Col>
                <Col
                  className={styles.prodDigit}
                  style={{ textAlign: "right", color: "#727176" }}
                >
                  12,5€/
                  <span className={styles.each}>
                    <FormattedMessage id="pricingPage.each" />
                  </span>
                  <Text text className={styles.save}>
                    <i>
                      <FormattedMessage id="pricingPage.save" /> 375€
                    </i>
                  </Text>
                </Col>
              </Row>
              <PrimaryButton
                classNames={styles.button2}
                onClick={() => {
                  if (typeof window !== "undefined") {
                    window.open(
                      `${COMPANY_URL}/request-demo?lang=${intl.locale}`,
                      "_blank"
                    );
                  }
                }}
                text={`${intl.formatMessage({
                  id: `pricingPage.button`,
                })}`}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col sm lg md className={styles.paymentCol}>
          <Card className={styles.priceCards}>
            <Card.Body className={styles.prodCardBody}>
              <Card.Title>
                <Text
                  title
                  className={classnames(styles.cardTitle, {
                    pricingtop: intl.locale === "es",
                  })}
                >
                  <FormattedMessage id="pricingPage.heading3" />
                </Text>
              </Card.Title>
              <Card.Text style={{ paddingBottom: "18px" }}>
                <Text text className={styles.cardText}>
                  <FormattedMessage id="pricingPage.text3" />
                </Text>
              </Card.Text>
              <div className={styles.prodDigit} style={{ fontSize: "52px" }}>
                20<span style={{ fontSize: "2.5rem" }}>%</span>
              </div>
              <Text text className={styles.save}>
                <FormattedMessage id="pricingPage.flat" />
              </Text>
              <Card.Text>
                <Text
                  text
                  className={styles.cardText}
                  style={{ margin: "16px 0" }}
                >
                  <FormattedMessage id="pricingPage.text4" />
                </Text>
              </Card.Text>
              <PrimaryButton
                classNames={styles.button2}
                onClick={() => {
                  if (typeof window !== "undefined") {
                    window.open(
                      `${COMPANY_URL}/request-demo?lang=${intl.locale}`,
                      "_blank"
                    );
                  }
                }}
                text={`${intl.formatMessage({
                  id: `pricingPage.button`,
                })}`}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row style={{ paddingTop: 0 }} className={styles.rows}>
        <Col sm lg md>
          <Card className={styles.firstPriceCards}>
            <Card.Body style={{ padding: 0 }}>
              <Card.Text>
                <Text text className={styles.cardText}>
                  <FormattedMessage id="pricingPage.text5" />
                </Text>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <Container className={styles.couponContainer}>
      <Row>
        <Col sm lg md style={{ padding: 0 }}>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder={`${intl.formatMessage({
                id: `pricingPage.input`,
              })}`}
              className={styles.input}
            />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  </>
);

Pricing.propTypes = {
  intl: PropTypes.string.isRequired,
  formatMessage: PropTypes.func.isRequired
}

export default injectIntl(Pricing);
